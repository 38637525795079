import Layout from '../../containers/Layout'
import Title from '../../components/Text/Title'
import Container from '../../containers/Container'

import React, { useState, useEffect } from 'react'
import Form from '../../components/Forms/FormV2'
import { IInputProps } from '../../components/Forms/InputV2'
import endpoints from '../../mixins/endpoints'
import api from '../../mixins/api'
import apiBaseUrl from '../../mixins/apiBaseUrl'
import Button from "@mui/material/Button"
import { styled } from '@mui/material/styles'
import ShortUrl from '../../models/ShortUrl'
import Logo from '../../models/Logo'
import DialogImg from '../../components/Modals/DialogImg'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Paper from '@mui/material/Paper'
import THEME from '../../styles/theme'
import { styled as styledComponent } from 'styled-components'
import AddIcon from '@mui/icons-material/AddCircleOutlineRounded';
import LinkIcon from '@mui/icons-material/LinkRounded';
import DownloadIcon from '@mui/icons-material/DownloadRounded';
import UpdateIcon from '@mui/icons-material/LoopRounded';
import DeleteIcon from '@mui/icons-material/DeleteOutlineRounded';
import { blue } from '@mui/material/colors'
import Paragraph from '../../components/Text/Paragraph';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';

import { DataGrid,  
  GridColDef, 
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector } from '@mui/x-data-grid';
  import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
  import * as locales from '@mui/material/locale';

export default function ShortUrlModule() {
    const rx = /^(?:1?[0-9]{1,2}|2[0-4][0-9]|25[0-5]),(?:1?[0-9]{1,2}|2[0-4][0-9]|25[0-5]),(?:1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])$/
    const [dataShortUrl, setDataShortUrl] = useState<ShortUrl[]>([]);
    const [dataLogos, setDataLogos] = useState<Logo[]>([]);

    const [displayDialogNew, setDisplayDialogNew] = useState(false)
    const [displayDialogUpdate, setDisplayDialogUpdate] = useState(false)
    const [displayDialogConfirmRemove, setDisplayDialogConfirmRemove] = useState(false)
    const [displayDialog, setDisplayDialog] = useState(false)
    const [dialogMessage, setDialogMessage] = useState("")
    const [displayDialogImg, setDisplayDialogImg] = useState(false)
    const [imgDialog, setImgDialog] = useState("")

    const [IdToUpdate, setIdToUpdate] = useState("")
    const [IdToRemove, setIdToRemove] = useState("")
    const [inputName, setInputName] = useState("")
    const [inputDescription, setInputDescription] = useState("")
    const [inputUrl, setInputUrl] = useState("")

    const [selectedLogo, setSelectedLogo] = useState("")
    const [qrCodeColor, setQrCodeColor] = useState("")

    const [qrCodeSize, setQrCodeSize] = useState("")
    const [selectedSize, setSelectedSize] = useState("")
    const [customSize, setCustomSize] = useState(false)
    
    const [errorInputFileMessage, setErrorInputFileMessage] = useState("")
    
    type SupportedLocales = keyof typeof locales;
    const [locale, setLocale] = React.useState<SupportedLocales>('frFR');
    const theme = useTheme();
    const themeWithLocale = React.useMemo(
      () => createTheme(theme, locales[locale]),
      [locale, theme],
    );

    const COLUMNLEFTBASE: IInputProps[] = 
    [
        {
          input:'text',
          type:'full',
          id:'inputUrl',
          required: true,
          label:'URL',
          labelPosition: 'left',
          name:'inputUrl',
          placeholder:'Veuillez saisir une URL',
          value: inputUrl,
          setValue: setInputUrl
        },
        {
          input:'text',
          type:'full',
          id:'inputName',
          required:true,
          label:"Nom",
          labelPosition: 'left',
          name:'inputName',
          placeholder:"Veuillez saisir un nom pour le raccourci",
          value: inputName,
          setValue: setInputName
        },
        {
          input:'text',
          type:'full',
          id:'inputDescription',
          label:'Description',
          labelPosition: 'left',
          name:'inputDescription',
          placeholder:'Description',
          multiline:true,
          rows:3,
          value: inputDescription,
          setValue: setInputDescription
        }
    ]
    
      const fetchShortUrls = async () => {
        api.methods.get(apiBaseUrl.data().digitalFramework + endpoints.data().GetAllShortUrlsEndpoint).then((response =>{
          setDataShortUrl(response.data)
            })).catch((error)=>{
                alert(error)
                console.log(error)
            })
        }

      const fetchLogos = async () => {
        api.methods.get(apiBaseUrl.data().digitalFramework + endpoints.data().GetAllLogoEndpoint).then((response =>{
          setDataLogos(response.data)
            })).catch((error)=>{
                alert(error)
                console.log(error)
            })
        }
      
      useEffect(() => {
        fetchShortUrls();
        fetchLogos();
      }, []);

    const updateSelectedLogo = (event: SelectChangeEvent) => {
      let value = event.target.value as string
      setSelectedLogo(value);
      setQrCodeColor(dataLogos.find((item)=> item.name === value)?.backgroundColor ?? "")
    };

    const updateSelectedSize = (event: SelectChangeEvent) => {
      let value = event.target.value as string
      if(value === "custom"){
          setCustomSize(true)
          setSelectedSize(value);
          setQrCodeSize(" ")
          
      }else{
          setCustomSize(false)
          setSelectedSize(value);
          setQrCodeSize(value)
      }
    };

    function setColor(params : string){
        setQrCodeColor(params)
    }

    function setSize(params : string){
      setQrCodeSize(params)
    }

    function confirmDelete(id: string){
      let obj = dataShortUrl.find(x => x.id === id)
      if(obj !== undefined && obj !== null){
        setDialogMessage("Suppression définitive du raccourci \"" + obj.name + "\" ?")
        setDisplayDialogConfirmRemove(true)
        setIdToRemove(id)
      }
    }

    function confirmUpdate(id: string){
      let obj = dataShortUrl.find(x => x.id === id)
      if(obj !== undefined && obj !== null){
        setDisplayDialogUpdate(true)
        setIdToUpdate(id)
        setInputUrl(obj.url)
        setInputName(obj.name)
        setInputDescription(obj.description)
      }
    }

    function copyLink(id: string){
      let result
      let obj = dataShortUrl.find(x => x.id === id)
      if(obj !== undefined && obj !== null){
        result = navigator.clipboard.writeText(obj.url)
      }

      return result
    }

    function getSrcImageBase64(id: string){
      let result
      let obj = dataShortUrl.find(x => x.id === id)
      if(obj !== undefined && obj !== null){
        result = `data:image/png;base64,`+ obj.imageBase64
      }

      return result
    }

    function generateFileName(id: string){
      let result;
      let obj = dataShortUrl.find(x => x.id === id)
      if(obj !== undefined && obj !== null){
        result = obj.name + '.png'
      }
  
      return result
    }

    const handleClose = () => {
      setDisplayDialogConfirmRemove(false);
      setDisplayDialog(false)
    };

    const handleCloseNew = () => {
      setDisplayDialogNew(false)
      setErrorInputFileMessage("")
      setInputUrl("")
      setInputName("")
      setInputDescription("")
      setSelectedLogo("")
      setColor("")
      setSelectedSize("")
      setQrCodeSize("")
      setCustomSize(false)
    };

    const handleCloseUpdate = () => {
      setDisplayDialogUpdate(false)
      setErrorInputFileMessage("")
      setInputUrl("")
      setInputName("")
      setInputDescription("")
    };

    const displayPreview = async(imgbase64: string) => {
      setImgDialog(imgbase64)
      setDisplayDialogImg(true)
    }
    
    function openNewDialog(){
      setDisplayDialogNew(true)
    }

    function checkInputNew(){
      if(qrCodeSize.trim() === ""){
        setErrorInputFileMessage("Veuillez renseigner une résolution")
      }
      else if(selectedLogo === ''){
        setErrorInputFileMessage("Veuillez sélectionner une entité")
      }
      else if(qrCodeColor !== '' && !rx.test(qrCodeColor)){
        setErrorInputFileMessage("Veuillez renseigner une couleur au format hexadécimal")
      }
      else if(inputUrl === ''){
        setErrorInputFileMessage("Veuillez renseigner une url")
      }
      else if(inputName === ''){
        setErrorInputFileMessage("Veuillez renseigner le nom du raccourci")
      }
      else{
        addNewShortUrl()    
      }
    }

    function checkInputUpdate(){
      if(inputName !== ""){
            if(inputUrl === ''){
              setErrorInputFileMessage("Veuillez renseigner une url")
            }
            else{    
              updateShortUrl()
            }
      }
      else{
          setErrorInputFileMessage("Veuillez renseigner le nom du raccourci")
      }
    }

    const addNewShortUrl = async () => {
      setDisplayDialogNew(false);

      let obj = {
          name: inputName,
          description: inputDescription,
          redirectUrl: inputUrl
      }

      api.methods.post(apiBaseUrl.data().digitalFramework + endpoints.data().NewShortUrlEndpoint + "?logoName="+ selectedLogo + "&qrCodeSize=" + qrCodeSize.trim()  + "&color=" + qrCodeColor, obj).then((() =>{
        handleCloseNew()
        fetchShortUrls()
      })).catch((error)=>{
          console.log(error)
          setDialogMessage("Un raccourci portant le même nom existe déjà")
          setDisplayDialog(true)
          handleCloseNew()
      })
    }

    const updateShortUrl = async () => {
      setDisplayDialogNew(false);

      let obj = {
          id: IdToUpdate,
          name: inputName,
          description: inputDescription,
          redirectUrl: inputUrl
      }

      api.methods.put(apiBaseUrl.data().digitalFramework + endpoints.data().UpdateShortUrlEndpoint, obj).then((response =>{
          setDialogMessage("Le raccourci a bien été modifié")
          setDisplayDialog(true)
          handleCloseUpdate()
          fetchShortUrls()
      })).catch((error)=>{
          console.log(error)
          setDialogMessage("Une erreur est apparue durant le traitement, veuillez informer l'équipe des administrateurs afin qu'ils puissent résoudre votre problème")
          setDisplayDialog(true)
          handleCloseUpdate()
      })
    }

    const deleteShortUrl = async () => {
        setDisplayDialogConfirmRemove(false);
        
        if(IdToRemove !== ""){
          api.methods.delete(apiBaseUrl.data().digitalFramework + endpoints.data().DeleteShortUrlEndpoint + "?id=" + IdToRemove).then(() =>{
            setIdToRemove("")
            fetchShortUrls()
              }).catch((error)=>{
                  alert(error)
                  console.log(error)
              })
        }
    }

    function formatDate(parameter:string, hideHour : boolean){
        let result = ''
        if(parameter !== null && parameter !== '')
        { 
          if(hideHour){
            result = new Date(parameter).toLocaleDateString([],{ year: 'numeric', month: 'numeric', day: 'numeric' });
          }else{
            result = new Date(parameter).toLocaleDateString([],{ year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' });
          }
        }
        return result
    }

    function formatUserName(parameter: string){
      let result = ''
      if(parameter !== null && parameter !== ''){
        result = parameter.split('@')[0]
      }
      return result
    }

    function CustomToolbar() {
      return (
        <GridToolbarContainer>
          <GridToolbarFilterButton />
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
        </GridToolbarContainer>
      );
    }

    const columns: GridColDef[] = [
      { field: 'name', headerName: 'Nom', width: 130 },
      { field: 'description', headerName: 'Description', width: 200 },
      { field: 'imageBase64', headerName: 'Aperçu', width: 100,
        renderCell: (param: GridRenderCellParams<any, string>)=>(
          <input type='image' 
            onClick={()=>displayPreview(param.value ? param.value : '')} 
            src={`data:image/png;base64,${param.value}`} 
            alt='' width={'80px'}/>
        )
       },
      { field: 'code', headerName: 'Code', width: 100 },
      { field: 'created', headerName: 'Créé', width: 130,
        renderCell: (param: GridRenderCellParams<any, string>)=>(
          formatDate(param.value ? param.value : '', false)
      )},
      { field: 'createdBy', headerName: 'Créé par', width: 140,
        renderCell: (param: GridRenderCellParams<any, string>)=>(
          formatUserName(param.value ? param.value : '')
      )},
      { field: 'lastUpdate', headerName: 'Mise à jour', width: 130,
        renderCell: (param: GridRenderCellParams<any, string>)=>(
          formatDate(param.value ? param.value : '', false)
      )},
      { field: 'lastUpdateBy', headerName: 'Mise à jour par', width: 140,
        renderCell: (param: GridRenderCellParams<any, string>)=>(
          formatUserName(param.value ? param.value : '')
      )},
      { field: 'id', headerName: 'Actions', width: 240, headerAlign: 'center', align:'left',
        renderCell: (param: GridRenderCellParams<any, string>)=>(
          <>
          <Button onClick={(e) =>{copyLink(param.value ? param.value : '')}}>
            <LinkIcon sx={{ fontSize: 40,color: blue[900] }} />
          </Button>
          <a download={generateFileName(param.value ? param.value : '')} href={getSrcImageBase64(param.value ? param.value : '')}>
            <DownloadIcon sx={{ fontSize: 40,color: blue[900] }}/>
          </a>
          <Button onClick={(e) =>{confirmUpdate(param.value ? param.value : '')}}>
            <UpdateIcon sx={{ fontSize: 40,color: blue[900] }}/>
          </Button>
          <Button onClick={(e) =>{confirmDelete(param.value ? param.value : '')}}>
            <DeleteIcon sx={{ fontSize: 40,color: blue[900] }}/>
          </Button>
          </>
      )},
    ];

    return (
        <>
        <Layout
            active=''
            type="main"
            >
            <BlockContainer>
                <Container>
                    <Title type='emphasis' size='title' label='Short Url' align='center' color="main" />
                    <Title type='general' size='subtitle' variant='section' label="Raccourcisseur d'URL" color="main" />
                    {/* Begin */}
                    <Button startIcon={<AddIcon />} 
                      variant="outlined" 
                      onClick={openNewDialog}
                      sx={[{fontWeight:'bold',backgroundColor: '#1B365F', color:'#ffffff'},
                        {'&:hover': {fontWeight:'bold', backgroundColor:'#ffffff', color:'#1B365F'}} 
                      ]}
                    >Ajouter</Button>
                    <br/><br/>
                    <DialogImg type='main' 
                      display={displayDialogImg} 
                      setDisplay={setDisplayDialogImg} 
                      title='AGL - Shortcut' 
                      imagebase64={imgDialog} 
                      copyEnabled={true}>customdialog
                    </DialogImg>
                    <Dialog
                      open={displayDialogNew} 
                      onClose={handleCloseNew}
                      PaperComponent={PaperStyle}
                      fullWidth={true}
                      //fullScreen={true}
                      >
                      <DialogTitle>Ajouter un raccourci</DialogTitle>
                      <DialogContent>
                        <center>
                          <Warning>{errorInputFileMessage}</Warning>
                        </center>
                      </DialogContent>
                          <Form
                                title=""
                                first={[]}
                                type="main"
                                form="full" 
                                design="snow"
                                corporate
                                >
                                  <FormControl sx={{ m: 1, width:'20ch' }} variant="standard">
                                      <InputLabel id="qrcodesize-label">Taille</InputLabel>
                                      <Select
                                          labelId="qrcodesize-label"
                                          id="qrcodesize-select"
                                          value={selectedSize}
                                          label="Taille"
                                          onChange={updateSelectedSize}
                                      >
                                          <MenuItem key="1" value="240">Web</MenuItem>
                                          <MenuItem key="2" value="720">SD</MenuItem>
                                          <MenuItem key="3" value="1280">HD</MenuItem>
                                          <MenuItem key="4" value="1920">FHD</MenuItem>
                                          <MenuItem key="5" value="3840">4K</MenuItem>
                                          <MenuItem key="6" value="custom">Personnalisée</MenuItem>
                                      </Select>
                                  </FormControl>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <FormControl sx={{ m: 1 }} variant="standard">
                                      <InputLabel htmlFor="textbox-qrcodesize">Résolution</InputLabel>
                                      <BootstrapInput id="textbox-qrcodesize" readOnly={!customSize} value={qrCodeSize} onChange={(e)=>setSize(e.target.value)}/>
                                  </FormControl>
                                  <br/>
                                  <FormControl sx={{ m: 1, width:'20ch' }} variant="standard">
                                    <InputLabel id="logos-label">Entité</InputLabel>
                                      <Select
                                          labelId="logos-label"
                                          id="logos-select"
                                          value={selectedLogo}
                                          label="Entité"
                                          onChange={updateSelectedLogo}
                                      >
                                          {dataLogos.map((item)=><MenuItem key={item.name} value={item.name}>{item.name}</MenuItem>)}
                                      </Select>
                                  </FormControl>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <FormControl sx={{ m: 1 }} variant="standard">
                                      <InputLabel htmlFor="demo-customized-textbox">Couleur RGB</InputLabel>
                                      <BootstrapInput id="demo-customized-textbox" value={qrCodeColor} onChange={(e)=>setColor(e.target.value)}/>
                                  </FormControl>
                                  {/* <br/><br/> */}
                                  <Form
                                title=""
                                first={COLUMNLEFTBASE}
                                type="main"
                                form="full" 
                                design="transparent"
                                corporate
                          ></Form>
                          </Form>
                          {/* <Form
                                title=""
                                first={COLUMNLEFTBASE}
                                type="main"
                                form="full" 
                                design="main"
                                corporate
                          ></Form> */}
                      <DialogActions>
                        <Button onClick={checkInputNew} 
                        sx={[{fontWeight:'bold',backgroundColor: '#1B365F', color:'#ffffff'},
                          {'&:hover': {fontWeight:'bold', backgroundColor:'#ffffff', color:'#1B365F'}} 
                        ]}
                        autoFocus>
                          Ajouter
                        </Button>
                        <Button onClick={handleCloseNew} 
                        sx={[{fontWeight:'bold',backgroundColor: '#1B365F', color:'#ffffff'},
                          {'&:hover': {fontWeight:'bold', backgroundColor:'#ffffff', color:'#1B365F'}} 
                        ]}
                        autoFocus>
                          Annuler
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Dialog
                      open={displayDialogUpdate} 
                      onClose={handleCloseUpdate}
                      PaperComponent={PaperStyle}
                      fullWidth={true}
                      //fullScreen={true}
                      >
                      <DialogTitle>Modifier un raccourci</DialogTitle>
                      <DialogContent>
                        Notice :<br/>
                        L'URL doit être valide<br/>
                        Un nom doit être renseigné
                        <center>
                          <Warning>{errorInputFileMessage}</Warning>
                        </center>
                      </DialogContent>
                          <Form
                                title=""
                                first={COLUMNLEFTBASE}
                                type="main"
                                form="full" 
                                design="main"
                                corporate
                                >
                          </Form>
                      <DialogActions>
                        <Button onClick={checkInputUpdate} 
                        sx={[{fontWeight:'bold',backgroundColor: '#1B365F', color:'#ffffff'},
                          {'&:hover': {fontWeight:'bold', backgroundColor:'#ffffff', color:'#1B365F'}} 
                        ]}
                        autoFocus>
                          Modifier
                        </Button>
                        <Button onClick={handleCloseUpdate} 
                        sx={[{fontWeight:'bold',backgroundColor: '#1B365F', color:'#ffffff'},
                          {'&:hover': {fontWeight:'bold', backgroundColor:'#ffffff', color:'#1B365F'}} 
                        ]}
                        autoFocus>
                          Annuler
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Dialog
                      open={displayDialogConfirmRemove} 
                      onClose={handleClose}
                      PaperComponent={PaperStyle}>
                      <DialogContent>
                        <ContentContainer >
                            {dialogMessage}
                        </ContentContainer>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={deleteShortUrl} 
                        sx={[{fontWeight:'bold',backgroundColor: '#1B365F', color:'#ffffff'},
                          {'&:hover': {fontWeight:'bold', backgroundColor:'#ffffff', color:'#1B365F'}} 
                        ]}
                        autoFocus>
                          Oui
                        </Button>
                        <Button onClick={handleClose} 
                        sx={[{fontWeight:'bold',backgroundColor: '#1B365F', color:'#ffffff'},
                          {'&:hover': {fontWeight:'bold', backgroundColor:'#ffffff', color:'#1B365F'}} 
                        ]}
                        autoFocus>
                          Non
                        </Button>
                      </DialogActions>
                    </Dialog> 
                    <Dialog
                      open={displayDialog} 
                      onClose={handleClose}
                      PaperComponent={PaperStyle}>
                      <DialogTitle>Gestion des raccourcis</DialogTitle>
                      <DialogContent>
                        <ContentContainer >
                            {dialogMessage}
                        </ContentContainer>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose} 
                        sx={[{fontWeight:'bold',backgroundColor: '#1B365F', color:'#ffffff'},
                          {'&:hover': {fontWeight:'bold', backgroundColor:'#ffffff', color:'#1B365F'}} 
                        ]}
                        autoFocus>
                          Ok
                        </Button>
                      </DialogActions>
                    </Dialog> 
                    <Paragraph type='general' size='subparagraph' color="main">            
                      <LinkIcon sx={{ fontSize: 20,color: blue[900] }}/> Copier le lien
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <DownloadIcon sx={{ fontSize: 20,color: blue[900] }}/> Télécharger
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <UpdateIcon sx={{ fontSize: 20,color: blue[900] }}/> Modifier
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <DeleteIcon sx={{ fontSize: 20,color: blue[900] }}/> Supprimer
                    </Paragraph>           
                    <Align>
                       <ThemeProvider theme={themeWithLocale}>
                        <DataGrid columns={columns} 
                                  rows={dataShortUrl} 
                                  rowSelection={false} 
                                  pageSizeOptions={[10,25,100]}
                                  initialState={{
                                    pagination: { 
                                      paginationModel: { pageSize: 10 },
                                    },
                                  }}
                                  slots={{ toolbar: CustomToolbar }}
                          />
                      </ThemeProvider>
                    </Align>
                    {/* End */}
                </Container>
            </BlockContainer>
        </Layout>   
        </>
    );
}

const BlockContainer = styledComponent.div`
  width: auto;
  position: relative;
  left: 20px;
  bottom: 50px;
`;

const PaperStyle = styled(Paper)`
  background-color: ${THEME.background.color.general};
  color: ${THEME.text.color.general};
`;

const ContentContainer = styledComponent.div`
  color: ${THEME.text.color.general};
`;

const Warning = styledComponent.div`
  color: #c82121;
  font-weight: bold;
`;

const Align = styledComponent.div`
p {
  margin-top: 13px
}
.MuiDataGrid-columnHeader{
  background-color: ${THEME.background.color.general};
  color: ${THEME.text.color.general};
}
.MuiButtonBase-root{
  color: ${THEME.background.color.general};
}
`;

const BootstrapInput = styled(InputBase)(() => ({
  'label + &': {
    marginTop: useTheme().spacing(2),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    width: '100px',
    textAlign: 'center',
    height:'10px',
    position: 'relative',
    backgroundColor: useTheme().palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: useTheme().transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));