import React, { useState, useEffect } from 'react'
import Layout from '../../../containers/Layout'
import Title from '../../../components/Text/Title'
import Container from '../../../containers/Container'
import Paragraph from '../../../components/Text/Paragraph';

import endpoints from '../../../mixins/endpoints'
import api from '../../../mixins/api'
import apiBaseUrl from '../../../mixins/apiBaseUrl'
import Record from '../../../models/Record'
import DialogImg from '../../../components/Modals/DialogImg'
import { styled } from '@mui/material/styles'
import Button from "@mui/material/Button"
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Paper from '@mui/material/Paper'
import THEME from '../../../styles/theme'
import { styled as styledComponent } from 'styled-components'
import LinkIcon from '@mui/icons-material/LinkRounded';
import VcardIcon from '@mui/icons-material/RecentActors';
import DownloadIcon from '@mui/icons-material/DownloadRounded';
import DeleteIcon from '@mui/icons-material/DeleteOutlineRounded';
import { blue } from '@mui/material/colors'

import { DataGrid,  
  GridColDef, 
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector } from '@mui/x-data-grid';
  import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
  import * as locales from '@mui/material/locale';

export default function DisplayQrCode() {
  const [data, setData] = useState<Record[]>([])
  const [displayDialogImg, setDisplayDialogImg] = useState(false)
  const [imgDialog, setImgDialog] = useState("")
  const [displayDialogConfirmRemove, setDisplayDialogConfirmRemove] = useState(false)
  const [dialogMessage, setDialogMessage] = useState("")
  const [recordIdToRemove, setRecordIdToRemove] = useState("")

  type SupportedLocales = keyof typeof locales;
    const [locale, setLocale] = React.useState<SupportedLocales>('frFR');
    const theme = useTheme();
    const themeWithLocale = React.useMemo(
      () => createTheme(theme, locales[locale]),
      [locale, theme],
    );

  useEffect(() => {
    fetchQrCode()
  }, []);

  const fetchQrCode = async () => {
    api.methods.get(apiBaseUrl.data().digitalFramework+ endpoints.data().GetAllQrCodeEndpoint).then((response =>{
      setData(response.data)
    })).catch((error)=>{
        alert(error)
        console.log(error)
    })
  }

  const displayPreview = async(imgbase64: string) => {
     setImgDialog(imgbase64)
     setDisplayDialogImg(true)
  }

  function formatTypeDisplay(value: number){
    let result = ""
    switch(value){
      case 0: result = "Simple contenu"
              break
      case 1: result = "Carte de visite"
              break
      default: break
    }

    return result
  }

  function modalDeleteRecord(id: string){
    let obj = data.find(x=>x.id === id)
    if(obj !== undefined && obj !== null){
      setDialogMessage("Suppression définitive du QR Code \"" + obj.name + "\" ?")
      setDisplayDialogConfirmRemove(true)
      setRecordIdToRemove(id)
    }
  }

  function formatDate(parameter:string, hideHour : boolean){
    var result = ''
    if(parameter !== '')
    { 
      if(hideHour){
        result = new Date(parameter).toLocaleDateString([],{ year: 'numeric', month: 'numeric', day: 'numeric' });
      }else{
        result = new Date(parameter).toLocaleDateString([],{ year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' });
      }
    }
    return result;
  }

  function formatUserName(parameter: string){
    let result = ''
    if(parameter !== null && parameter !== ''){
      result = parameter.split('@')[0]
    }
    return result
  }

  const deleteRecord = async () => {
    setDisplayDialogConfirmRemove(false);
    
    if(recordIdToRemove !== ""){
      api.methods.delete(apiBaseUrl.data().digitalFramework + endpoints.data().DeleteQrCodeEndpoint + "?id=" + recordIdToRemove).then(() =>{
        setRecordIdToRemove("")
        fetchQrCode()
          }).catch((error)=>{
              alert(error)
              console.log(error)
          })
    }
  }

  const handleClose = () => {
    setDisplayDialogConfirmRemove(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  function copyLink(id: string){
    let obj = data.find(x=>x.id === id)
    if(obj !== undefined && obj !== null){
      let link = process.env.REACT_APP_BlobStorageUrl + '/qrcode/' + obj.storageId + '/' + obj.fileName
      navigator.clipboard.writeText(link.replaceAll("\r\n","\\r\\n"))
    }
  }

  function copyVcard(id: string){
    let obj = data.find(x=>x.id === id)
    if(obj !== undefined && obj !== null){
      navigator.clipboard.writeText(obj.vcard.replaceAll("\r\n","\\r\\n"))
    }
  }

  function checkVcard(id: string){
    let result = false;
    let obj = data.find(x=>x.id === id)
    if(obj !== undefined && obj !== null){
      if(obj.type === 1 && obj.vcard !== null){
        result = true
      }
    }

    return result
  }

  function generateFileName(id: string){
    let result;
    let obj = data.find(x=>x.id === id)
    if(obj !== undefined && obj !== null){
      result = obj.name + '.png'
    }

    return result
  }

  function getSrcImageBase64(id: string){
    let result;
    let obj = data.find(x=>x.id === id)
    if(obj !== undefined && obj !== null){
      result = `data:image/png;base64,`+ obj.imageBase64
    }

    return result
  }
  
  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Nom', width: 130 },
    { field: 'type', headerName: 'Type', width: 130,
      renderCell: (param: GridRenderCellParams<any, number>)=>(
        formatTypeDisplay(param.value ? param.value : 0)
    )},
    { field: 'entity', headerName: 'Entité', width: 100 },
    { field: 'backgroundColor', headerName: 'Couleur RGB', width: 100 },
    { field: 'imageBase64', headerName: 'Aperçu', width: 100,
      renderCell: (param: GridRenderCellParams<any, string>)=>(
        <input type='image' 
          onClick={()=>displayPreview(param.value ? param.value : '')} 
          src={`data:image/png;base64,${param.value}`} 
          alt='' width={'80px'}/>
      )
     },
    { field: 'created', headerName: 'Créé', width: 130,
      renderCell: (param: GridRenderCellParams<any, string>)=>(
        formatDate(param.value ? param.value : '', false)
    )},
    { field: 'createdBy', headerName: 'Créé par', width: 140,
      renderCell: (param: GridRenderCellParams<any, string>)=>(
        formatUserName(param.value ? param.value : '')
    )},
    { field: 'id', headerName: 'Actions', width: 240, headerAlign: 'center', align:'left',
      renderCell: (param: GridRenderCellParams<any, string>)=>(
        <>
        <Button onClick={(e) =>{copyLink(param.value ? param.value : '')}}>
          <LinkIcon sx={{ fontSize: 40,color: blue[900] }} />
        </Button>
        { checkVcard(param.value ? param.value : '') !== false ?
          <Button onClick={(e) =>{copyVcard(param.value ? param.value : '')}}>
            <VcardIcon sx={{ fontSize: 40,color: blue[900] }}/>
          </Button> 
        : null
        }
        <a download={generateFileName(param.value ? param.value : '')} href={getSrcImageBase64(param.value ? param.value : '')}>
          <DownloadIcon sx={{ fontSize: 40,color: blue[900] }}/>
        </a>
        <Button onClick={(e) =>{modalDeleteRecord(param.value ? param.value : '')}}>
          <DeleteIcon sx={{ fontSize: 40,color: blue[900] }}/>
        </Button>
        </>
    )},
  ];

  return (
    <>
        <Layout
            active=''
            type="main"
            >
            <BlockContainer>
                <Container>
                    <Title type='emphasis' size='title' label='QRCode' align='center' color="main" />
                    <Title type='general' size='subtitle' variant='section' label='Enregistrés' color="main" />
                    {/* Begin */}
                    <DialogImg type='main' 
                      display={displayDialogImg} 
                      setDisplay={setDisplayDialogImg} 
                      // title='AGL - QR Code' 
                      imagebase64={imgDialog} 
                      copyEnabled={true}>customdialog
                    </DialogImg>
                    <Dialog
                      open={displayDialogConfirmRemove} 
                      onClose={handleClose}
                      PaperComponent={PaperStyle}>
                      <DialogContent>
                        <ContentContainer >
                            {dialogMessage}
                        </ContentContainer>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={deleteRecord} 
                        sx={[{fontWeight:'bold',backgroundColor: '#1B365F', color:'#ffffff'},
                          {'&:hover': {fontWeight:'bold', backgroundColor:'#ffffff', color:'#1B365F'}} 
                        ]}
                        autoFocus>
                          Oui
                        </Button>
                        <Button onClick={handleClose} 
                        sx={[{fontWeight:'bold',backgroundColor: '#1B365F', color:'#ffffff'},
                          {'&:hover': {fontWeight:'bold', backgroundColor:'#ffffff', color:'#1B365F'}} 
                        ]}
                        autoFocus>
                          Non
                        </Button>
                      </DialogActions>
                    </Dialog> 
                    <Paragraph type='general' size='subparagraph' color="main">
                      <LinkIcon sx={{ fontSize: 20,color: blue[900] }} /> Copier le Lien
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <VcardIcon sx={{ fontSize: 20,color: blue[900] }}/> Copier le contenu Vcard
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <DownloadIcon sx={{ fontSize: 20,color: blue[900] }}/> Télécharger
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <DeleteIcon sx={{ fontSize: 20,color: blue[900] }}/> Supprimer
                    </Paragraph>
                    <Align>
                      <ThemeProvider theme={themeWithLocale}>
                        <DataGrid columns={columns} 
                                  rows={data} 
                                  rowSelection={false} 
                                  pageSizeOptions={[10,25,100]}
                                  initialState={{
                                    pagination: { 
                                      paginationModel: { pageSize: 10 },
                                    },
                                  }}
                                  slots={{ toolbar: CustomToolbar }}
                          />
                      </ThemeProvider>
                    </Align>
                    {/* End */}
                </Container>
            </BlockContainer>
        </Layout> 
    </>
  );
}

const BlockContainer = styledComponent.div`
  width: auto;
  position: relative;
  left: 20px;
  bottom: 50px;
`;

const PaperStyle = styled(Paper)`
  background-color: ${THEME.background.color.general};
  color: ${THEME.text.color.general};
`;

const ContentContainer = styledComponent.div`
  color: ${THEME.text.color.general};
`;

const Align = styledComponent.div`
p {
  margin-top: 13px
}
.MuiDataGrid-columnHeader{
   background-color: ${THEME.background.color.general};
   color: ${THEME.text.color.general};
}
.MuiButtonBase-root{
  color: ${THEME.background.color.general};
}
`;