import Layout from '../../containers/Layout'
import Title from '../../components/Text/Title'
import Container from '../../containers/Container'

import React, { useState, useEffect } from 'react';
import Form from '../../components/Forms/Form';
import { IInputProps } from '../../components/Forms/Input';
import endpoints from '../../mixins/endpoints';
import api from '../../mixins/api';
import apiBaseUrl from '../../mixins/apiBaseUrl';
import Button from "@mui/material/Button"
import { styled } from '@mui/material/styles'
import Logo from '../../models/Logo'
import DialogImg from '../../components/Modals/DialogImg'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import THEME from '../../styles/theme';
import { styled as styledComponent } from 'styled-components';
import AddIcon from '@mui/icons-material/AddCircleOutlineRounded';
import UpdateIcon from '@mui/icons-material/LoopRounded';
import DeleteIcon from '@mui/icons-material/DeleteOutlineRounded';
import { blue } from '@mui/material/colors'
import Paragraph from '../../components/Text/Paragraph';
import { DataGrid,  
         GridColDef, 
         GridRenderCellParams,
         GridToolbarContainer,
         GridToolbarColumnsButton,
         GridToolbarFilterButton,
         GridToolbarDensitySelector } from '@mui/x-data-grid';
         import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
         import * as locales from '@mui/material/locale';

function Entities() {
    const rx = /^(?:1?[0-9]{1,2}|2[0-4][0-9]|25[0-5]),(?:1?[0-9]{1,2}|2[0-4][0-9]|25[0-5]),(?:1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])$/
    const [dataLogos, setDataLogos] = useState<Logo[]>([]);
    const [displayDialogNewLogo, setDisplayDialogNewLogo] = useState(false)
    const [displayDialogUpdateLogo, setDisplayDialogUpdateLogo] = useState(false)
    const [displayDialogConfirmRemove, setDisplayDialogConfirmRemove] = useState(false)
    const [displayDialog, setDisplayDialog] = useState(false)
    const [dialogMessage, setDialogMessage] = useState("")
    const [logoIdToUpdate, setLogoIdToUpdate] = useState("")
    const [logoIdToRemove, setLogoIdToRemove] = useState("")
    const [inputLogoName, setInputLogoName] = useState("")
    const [inputLogoBackgroundColor, setInputLogoBackgroundColor] = useState("")
    const [inputLogoFile, setInputLogoFile] = useState<File>()
    const [errorInputFileMessage, setErrorInputFileMessage] = useState("")
    const [logoFormData, setLogoFormData] = useState<FormData>()
    const [displayDialogImg, setDisplayDialogImg] = useState(false)
    const [imgDialog, setImgDialog] = useState("")

    const COLUMNLEFTBASE: IInputProps[] = 
    [
        {
          input:'text',
          type:'full',
          id:'inputLogoName',
          label:"Nom de l'entité (requis)",
          labelPosition: 'left',
          name:'inputLogoName',
          placeholder:"Nom de l'entité (requis)",
          value: inputLogoName,
          setValue: setInputLogoName
        },
        {
          input:'text',
          type:'full',
          id:'inputLogoBackgroundColor',
          label:'Couleur RGB associée',
          labelPosition: 'left',
          name:'inputLogoBackgroundColor',
          placeholder:'Si vide alors la couleur AGL sera appliquée par défaut',
          value: inputLogoBackgroundColor,
          setValue: setInputLogoBackgroundColor
      }
    ]

    type SupportedLocales = keyof typeof locales;
    const [locale, setLocale] = React.useState<SupportedLocales>('frFR');
    const theme = useTheme();
    const themeWithLocale = React.useMemo(
      () => createTheme(theme, locales[locale]),
      [locale, theme],
    );
    
    const fetchLogos = async () => {
      api.methods.get(apiBaseUrl.data().digitalFramework + endpoints.data().GetAllLogoEndpoint).then((response =>{
        setDataLogos(response.data)
          })).catch((error)=>{
              alert(error)
              console.log(error)
          })
      }
      
      useEffect(() => {
        fetchLogos();
      }, []);

      function modalUpdateLogo(logoId: string){
        let obj = dataLogos.find(x=>x.id === logoId)
        if(obj !== undefined && obj !== null){
          setDisplayDialogUpdateLogo(true)
          setLogoIdToUpdate(logoId)
          setInputLogoName(obj?.name)
          setInputLogoBackgroundColor(obj?.backgroundColor)
        }
      }

      function modalDeleteLogo(logoId: string){
        let obj = dataLogos.find(x=>x.id === logoId)
        if(obj !== undefined && obj !== null){
          setDialogMessage("Suppression définitive de l'entité \"" + obj.name + "\" ?")
          setDisplayDialogConfirmRemove(true)
          setLogoIdToRemove(logoId)
        }
      }

      const handleClose = () => {
        setDisplayDialogConfirmRemove(false);
        setDisplayDialog(false)
      };

      const handleCloseNewLogo = () => {
        setDisplayDialogNewLogo(false)
        setErrorInputFileMessage("")
        setInputLogoFile(undefined)
        setLogoFormData(undefined)
        setInputLogoName("")
        setInputLogoBackgroundColor("")
      };

      const handleCloseUpdateLogo = () => {
        setDisplayDialogUpdateLogo(false)
        setErrorInputFileMessage("")
        setInputLogoFile(undefined)
        setLogoFormData(undefined)
        setInputLogoName("")
        setInputLogoBackgroundColor("")
      };

      const displayPreview = async(imgbase64: string) => {
        setImgDialog(imgbase64)
        setDisplayDialogImg(true)
      }
      
      function openNewLogoDialog(){
        setDisplayDialogNewLogo(true)
      }

      function checkInputNewLogo(){
        var fileExtension = inputLogoFile?.name.split(".").at(-1);
        const allowedFileTypes = ["jpg", "png"];

        if (allowedFileTypes.includes(fileExtension?.toLowerCase() ?? "unknow")) {
            if(inputLogoName !== ""){
              if(inputLogoBackgroundColor !== '' && !rx.test(inputLogoBackgroundColor)){
                setErrorInputFileMessage("Veuillez renseigner une couleur composée de trois valeurs allant de 0 à 255 séparées par une virgule (exemple: 50,45,255)")
              }
              else{
                addNewLogo()
              }    
            }
            else{
              setErrorInputFileMessage("Veuillez renseigner le nom du logo")
            }
        }else{
          setErrorInputFileMessage("Le format du fichier n'a pas été reconnu")
        }
      }

      function checkInputUpdateLogo(){
        var fileExtension = inputLogoFile?.name.split(".").at(-1);
        const allowedFileTypes = ["jpg", "png"];

        if (allowedFileTypes.includes(fileExtension?.toLowerCase() ?? "unknow")) {
            if(inputLogoName !== ""){
              if(inputLogoBackgroundColor !== '' && !rx.test(inputLogoBackgroundColor)){
                setErrorInputFileMessage("Veuillez renseigner une couleur composée de trois valeurs allant de 0 à 255 séparées par une virgule (exemple: 50,45,255)")
              }
              else{    
                updateNewLogo()
              }
            }
            else{
              setErrorInputFileMessage("Veuillez renseigner le nom du logo")
            }
        }else{
          setErrorInputFileMessage("Le format du fichier n'a pas été reconnu")
        }
      }

      const handleFileUpload = (event: HTMLInputElement) => {
        var file = event !== null && event.files !== null ? event.files[0] : null;
        if(file !== null){
          const formData = new FormData();
          formData.append("logo", file);

          setLogoFormData(formData)
          setInputLogoFile(file)
        }
      };

      const addNewLogo = async () => {
        setDisplayDialogNewLogo(false);
        api.methods.post(apiBaseUrl.data().digitalFramework + endpoints.data().AddNewLogoEndpoint+ "?name=" + inputLogoName + "&backgroundColor=" + inputLogoBackgroundColor, logoFormData).then((() =>{
          handleCloseNewLogo()
          fetchLogos()
        })).catch((error)=>{
            console.log(error)
            setDialogMessage("Un logo portant le même nom existe déjà")
            setDisplayDialog(true)
            handleCloseNewLogo()
        })
      }

      const updateNewLogo = async () => {
        setDisplayDialogNewLogo(false);
        api.methods.put(apiBaseUrl.data().digitalFramework + endpoints.data().UpdateLogoEndpoint+ "?existingLogoId=" + logoIdToUpdate + "&newLogoName=" + inputLogoName + "&newBackgroundColor=" + inputLogoBackgroundColor, logoFormData).then((response =>{
            setDialogMessage("L'entité a bien été modifiée")
            setDisplayDialog(true)
            handleCloseUpdateLogo()
            fetchLogos()
        })).catch((error)=>{
            console.log(error)
            setDialogMessage("Une erreur est apparue durant le traitement, informer l'équipe administrateurs afin qu'ils puissent résoudre votre problème")
            setDisplayDialog(true)
            handleCloseUpdateLogo()
        })
      }

      const deleteLogo = async () => {
        setDisplayDialogConfirmRemove(false);
        
        if(logoIdToRemove !== ""){
          api.methods.delete(apiBaseUrl.data().digitalFramework + endpoints.data().DeleteLogoEndpoint + "?logoId=" + logoIdToRemove).then(() =>{
            setLogoIdToRemove("")
            fetchLogos()
              }).catch((error)=>{
                  alert(error)
                  console.log(error)
              })
        }
      }

      function formatBytes(size: string, decimals = 2) {
        var bytes = Number(size)
        if (!+bytes) return '0 Bytes'
    
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo']
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
    
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    function formatDate(parameter:string, hideHour : boolean){
        var result = ''
        if(parameter !== null && parameter !== '')
        { 
          if(hideHour){
            result = new Date(parameter).toLocaleDateString([],{ year: 'numeric', month: 'numeric', day: 'numeric' });
          }else{
            result = new Date(parameter).toLocaleDateString([],{ year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' });
          }
        }
        return result;
      }

    function formatUserName(parameter: string){
      let result = ''
      if(parameter !== null && parameter !== ''){
        result = parameter.split('@')[0]
      }
      return result
    }

    function CustomToolbar() {
      return (
        <GridToolbarContainer>
          <GridToolbarFilterButton />
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
        </GridToolbarContainer>
      );
    }
    
    const columns: GridColDef[] = [
      { field: 'name', headerName: 'Nom', width: 130 },
      { field: 'imageBase64', headerName: 'Aperçu', width: 100,
        renderCell: (param: GridRenderCellParams<any, string>)=>(
          <input type='image' 
            onClick={()=>displayPreview(param.value ? param.value : '')} 
            src={`data:image/png;base64,${param.value}`} 
            alt='' width={'80px'}/>
        )
       },
      { field: 'backgroundColor', headerName: 'Couleur RGB', width: 100 },
      { field: 'size', headerName: 'Taille', width: 100,
        renderCell: (param: GridRenderCellParams<any, string>)=>(
          formatBytes(param.value ? param.value : '')
      )},
      { field: 'created', headerName: 'Créé', width: 130,
        renderCell: (param: GridRenderCellParams<any, string>)=>(
          formatDate(param.value ? param.value : '', false)
      )},
      { field: 'createdBy', headerName: 'Créé par', width: 140,
        renderCell: (param: GridRenderCellParams<any, string>)=>(
          formatUserName(param.value ? param.value : '')
      )},
      { field: 'lastUpdate', headerName: 'Mise à jour', width: 130, 
        renderCell: (param: GridRenderCellParams<any, string>)=>(
          formatDate(param.value ? param.value : '', false)
      )},
      { field: 'lastUpdateBy', headerName: 'Mise à jour par', width: 140,
        renderCell: (param: GridRenderCellParams<any, string>)=>(
          formatUserName(param.value ? param.value : '')
      )},
      { field: 'id', headerName: 'Actions', width: 150, headerAlign: 'center', align:'center',
        renderCell: (param: GridRenderCellParams<any, string>)=>(
          <>
          <Button onClick={(e) =>{modalUpdateLogo(param.value? param.value: '')}}>
              <UpdateIcon sx={{ fontSize: 40,color: blue[900] }}/>
          </Button>
          <Button onClick={(e) =>{modalDeleteLogo(param.value? param.value: '')}}>
              <DeleteIcon sx={{ fontSize: 40,color: blue[900] }}/>
          </Button>
          </>
      )},
    ];

    return (
        <>
        <Layout
            active=''
            type="main"
            >
            <BlockContainer>
                <Container>
                    <Title type='emphasis' size='title' label='Entités' align='center' color="main" />
                    <Title type='general' size='subtitle' variant='section' label="Gestion" color="main" />
                    {/* Begin */}
                    <Button startIcon={<AddIcon />} 
                      variant="outlined" 
                      onClick={openNewLogoDialog}
                      sx={[{fontWeight:'bold',backgroundColor: '#1B365F', color:'#ffffff'},
                        {'&:hover': {fontWeight:'bold', backgroundColor:'#ffffff', color:'#1B365F'}} 
                      ]}
                      >Ajouter</Button>
                    <br/><br/>
                    <DialogImg type='main' 
                      display={displayDialogImg} 
                      setDisplay={setDisplayDialogImg} 
                      title='AGL - Entité' 
                      imagebase64={imgDialog} 
                      copyEnabled={false}>customdialog
                    </DialogImg>
                    <Dialog
                      open={displayDialogNewLogo} 
                      onClose={handleCloseNewLogo}
                      PaperComponent={PaperStyle}
                      fullWidth={true}
                      //fullScreen={true}
                      >
                      {/* <DialogTitle>Ajouter une entité</DialogTitle> */}
                      <DialogContent>
                        Format autorisé : PNG ou JPEG<br/>
                        Poids max : 1 mo<br/><br/>
                        <Important> Il est recommandée d'utiliser une image ayant une résolution élevée afin d'avoir un meilleur rendu.</Important>
                        <center>
                          <Warning>{errorInputFileMessage}</Warning>
                        </center>
                      </DialogContent>
                          <Form
                                title=""
                                first={COLUMNLEFTBASE}
                                type="main"
                                form="full" 
                                design="main"
                                corporate
                                >
                              <input type="file" onChange={(e)=>{handleFileUpload(e.currentTarget)}}/>
                          </Form>
                      <DialogActions>
                        <Button variant="contained" 
                        onClick={checkInputNewLogo}
                        sx={[{fontWeight:'bold',backgroundColor: '#1B365F', color:'#ffffff'},
                          {'&:hover': {fontWeight:'bold', backgroundColor:'#ffffff', color:'#1B365F'}} 
                        ]} autoFocus>
                          Ajouter
                        </Button>
                        <Button variant="contained" 
                        onClick={handleCloseNewLogo}
                        sx={[{fontWeight:'bold',backgroundColor: '#1B365F', color:'#ffffff'},
                          {'&:hover': {fontWeight:'bold', backgroundColor:'#ffffff', color:'#1B365F'}} 
                        ]} autoFocus>
                          Annuler
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Dialog
                      open={displayDialogUpdateLogo} 
                      onClose={handleCloseUpdateLogo}
                      PaperComponent={PaperStyle}
                      fullWidth={true}
                      //fullScreen={true}
                      >
                      {/* <DialogTitle>Modifier une entité</DialogTitle> */}
                      <DialogContent>
                        Format autorisé : PNG ou JPEG<br/>
                        Poids max : 1 mo<br/><br/>
                        <Important> Il est recommandée d'utiliser une image ayant une résolution élevée afin d'avoir un meilleur rendu.</Important>
                        <center>
                          <Warning>{errorInputFileMessage}</Warning>
                        </center>
                      </DialogContent>
                          <Form
                                title=""
                                first={COLUMNLEFTBASE}
                                type="main"
                                form="full" 
                                design="main"
                                corporate
                                >
                              <input type="file" onChange={(e)=>{handleFileUpload(e.currentTarget)}}/>
                          </Form>
                      <DialogActions>
                        <Button onClick={checkInputUpdateLogo} 
                        sx={[{fontWeight:'bold',backgroundColor: '#1B365F', color:'#ffffff'},
                          {'&:hover': {fontWeight:'bold', backgroundColor:'#ffffff', color:'#1B365F'}} 
                        ]}
                        autoFocus>
                          Modifier
                        </Button>
                        <Button onClick={handleCloseUpdateLogo} 
                        sx={[{fontWeight:'bold',backgroundColor: '#1B365F', color:'#ffffff'},
                          {'&:hover': {fontWeight:'bold', backgroundColor:'#ffffff', color:'#1B365F'}} 
                        ]}
                        autoFocus>
                          Annuler
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Dialog
                      open={displayDialogConfirmRemove} 
                      onClose={handleClose}
                      PaperComponent={PaperStyle}>
                      <DialogContent>
                        <ContentContainer >
                            {dialogMessage}
                        </ContentContainer>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={deleteLogo} 
                          sx={[{fontWeight:'bold',backgroundColor: '#1B365F', color:'#ffffff'},
                            {'&:hover': {fontWeight:'bold', backgroundColor:'#ffffff', color:'#1B365F'}} 
                            ]} autoFocus>
                          Oui
                        </Button>
                        <Button onClick={handleClose} 
                          sx={[{fontWeight:'bold',backgroundColor: '#1B365F', color:'#ffffff'},
                            {'&:hover': {fontWeight:'bold', backgroundColor:'#ffffff', color:'#1B365F'}} 
                          ]}
                          autoFocus>
                          Non
                        </Button>
                      </DialogActions>
                    </Dialog> 
                    <Dialog
                      open={displayDialog} 
                      onClose={handleClose}
                      PaperComponent={PaperStyle}>
                      <DialogTitle>Gestion des entitées</DialogTitle>
                      <DialogContent>
                        <ContentContainer >
                            {dialogMessage}
                        </ContentContainer>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}
                          sx={[{fontWeight:'bold',backgroundColor: '#1B365F', color:'#ffffff'},
                            {'&:hover': {fontWeight:'bold', backgroundColor:'#ffffff', color:'#1B365F'}} 
                          ]}
                          autoFocus>
                          Ok
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Paragraph type='general' size='subparagraph' color="main">            
                      <UpdateIcon sx={{ fontSize: 20,color: blue[900] }}/> Modifier
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <DeleteIcon sx={{ fontSize: 20,color: blue[900] }}/> Supprimer
                    </Paragraph>
                    <Align>
                      <ThemeProvider theme={themeWithLocale}>
                        <DataGrid columns={columns} 
                                  rows={dataLogos} 
                                  rowSelection={false} 
                                  pageSizeOptions={[10,25,100]}
                                  initialState={{
                                    pagination: { 
                                      paginationModel: { pageSize: 10 },
                                    },
                                  }}
                                  slots={{ toolbar: CustomToolbar }}
                          />
                      </ThemeProvider>
                    </Align>
                    {/* End */}
                </Container>
            </BlockContainer>
        </Layout>   
        </>
    );
}


export default Entities;

const BlockContainer = styledComponent.div`
  width: auto;
  position: relative;
  left: 20px;
  bottom: 50px;
`;


const PaperStyle = styled(Paper)`
  background-color: ${THEME.background.color.general};
  color: ${THEME.text.color.general};
`;

const ContentContainer = styledComponent.div`
  color: ${THEME.text.color.general};
`;

const Warning = styledComponent.div`
  color: #c82121;
`;

const Important = styledComponent.div`
  color: #FE9900;
`;

const Align = styledComponent.div`
p {
  margin-top: 13px
}
.MuiDataGrid-columnHeader{
   background-color: ${THEME.background.color.general};
   color: ${THEME.text.color.general};
}
.MuiButtonBase-root{
  color: ${THEME.background.color.general};
}
`;