import { styled } from 'styled-components'
import Layout from '../../../containers/Layout'
import Title from '../../../components/Text/Title'
import Container from '../../../containers/Container'

import { useState ,useEffect} from 'react';
import Form from '../../../components/Forms/FormV2';
import { IInputProps } from '../../../components/Forms/InputV2';
import endpoints from '../../../mixins/endpoints';
import api from '../../../mixins/api';
import apiBaseUrl from '../../../mixins/apiBaseUrl';
import Dialog from '../../../components/Modals/Dialog';
import DialogImg from '../../../components/Modals/DialogImg';
import Logo from '../../../models/Logo'
import Button from "@mui/material/Button";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { useTheme } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { blue, grey } from '@mui/material/colors';
import SendIcon from '@mui/icons-material/Send';

function SimpleContent() {
    const rx = /^(?:1?[0-9]{1,2}|2[0-4][0-9]|25[0-5]),(?:1?[0-9]{1,2}|2[0-4][0-9]|25[0-5]),(?:1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])$/
    const [dataLogos, setDataLogos] = useState<Logo[]>([]);
    const [selectedLogo, setSelectedLogo] = useState("")

    const [content, setcontent] = useState("")
    const [displayDialog, setDisplayDialog] = useState(false)
    const [dialogMessage, setDialogMessage] = useState("")
    const [displayDialogImg, setDisplayDialogImg] = useState(false)
    const [imgDialog, setimgDialog] = useState("")
    const [qrCodeColor, setQrCodeColor] = useState("")
    
    const [qrCodeSize, setQrCodeSize] = useState("")
    const [selectedSize, setSelectedSize] = useState("")
    const [customSize, setCustomSize] = useState(false)

    const [record, setRecord] = useState(false)
    const [qrCodeName, setQrCodeName] = useState("")
    const QRRECORDED: IInputProps[] = [
        {
            input:'text',
            type:'full',
            id:'name',
            label:'Nom du QR Code',
            required: true,
            name:'name',
            placeholder:'Veuillez saisir un nom',
            value: qrCodeName,
            setValue: setQrCodeName
        },
        {
            input:'text',
            type:'full',
            id:'content',
            required: true,
            label:'Contenu',
            name:'content',
            placeholder:'Veuillez saisir un contenu : message, Url etc..',
            value: content,
            setValue: setcontent
        }
    ]
    const QRPONCTUAL: IInputProps[] = [
        {
            input:'text',
            type:'full',
            id:'content',
            required: true,
            label:'Contenu',
            name:'content',
            placeholder:'Veuillez saisir un contenu : message, Url etc..',
            value: content,
            setValue: setcontent
        }
    ]
    const fetchLogos = async () => {
        api.methods.get(apiBaseUrl.data().digitalFramework + endpoints.data().GetAllLogoEndpoint).then((response =>{
          setDataLogos(response.data)
            })).catch((error)=>{
                alert(error)
                console.log(error)
            })
        }

        useEffect(() => {
          fetchLogos()
        }, []);

    const updateSelectedLogo = (event: SelectChangeEvent) => {
        let value = event.target.value as string
        setSelectedLogo(value);
        setQrCodeColor(dataLogos.find((item)=> item.name === value)?.backgroundColor ?? "")
      };

    const updateSelectedSize = (event: SelectChangeEvent) => {
        let value = event.target.value as string
        if(value === "custom"){
            setCustomSize(true)
            setSelectedSize(value);
            setQrCodeSize(" ")
            
        }else{
            setCustomSize(false)
            setSelectedSize(value);
            setQrCodeSize(value)
        }
      };

    function setColor(params : string){
        setQrCodeColor(params)
    }

    function setSize(params : string){
        setQrCodeSize(params)
    }

    function updateRecordCheckbox(){
        if(!record){
            setRecord(true)
        }
        else{
            setRecord(false)
        }
    }

    function checkInputs(){
        if(qrCodeSize.trim() === ""){
            setDialogMessage("Veuillez renseigner une résolution")
            setDisplayDialog(true)
        }
        else if(selectedLogo === ""){
            setDialogMessage("Veuillez sélectionner une entité")
            setDisplayDialog(true)
        }
        else if(content.trim() === ''){
            setDialogMessage("Veuillez renseigner un contenu")
            setDisplayDialog(true)
        }
        else if(qrCodeColor !== '' && !rx.test(qrCodeColor)){
            setDialogMessage("Veuillez renseigner une couleur composée de trois valeurs allant de 0 à 255 séparées par une virgule (exemple: 50,45,255)")
            setDisplayDialog(true)
        }
        else if(record && qrCodeName.trim() === ''){
            setDialogMessage("Veuillez renseigner un nom pour le QR Code")
            setDisplayDialog(true)
        }
        else{
            QrCodeRequest()
        }
    }

    function QrCodeRequest(){
        api.methods.get(apiBaseUrl.data().digitalFramework + endpoints.data().BuildQrCodeEndpoint + "?logoName="+ selectedLogo + "&qrCodeSize=" + qrCodeSize.trim() + "&content=" + content + "&color=" + qrCodeColor + "&record=" + record + "&recordName=" + qrCodeName).then((response =>{
            setimgDialog(response.data.result.image)
            setDisplayDialogImg(true)
        })).catch((error)=>{
            console.log(error)
            setDialogMessage("Un QR Code portant le même nom existe déjà")
            setDisplayDialog(true)
        })
    }

    return (
        <>
        <Layout
            active=''
            type="main"
            >
            <BlockContainer>
                <Container>
                    <Title type='emphasis' size='title' label='QRCode' align='center' color="main" />
                    <Title type='general' size='subtitle' variant='section' label='Contenu simple' color="main" />
                    {/* Begin */}
                    <Dialog type='main' display={displayDialog} setDisplay={setDisplayDialog} title='AGL - QR Code' text={dialogMessage}>customdialog</Dialog>
                    <DialogImg type='main' display={displayDialogImg} setDisplay={setDisplayDialogImg} title='AGL - QR Code' imagebase64={imgDialog} copyEnabled={true}>customdialog</DialogImg>
                    <center>
                        <FormControlLabel control={<Checkbox sx={{color: grey[600],'&.Mui-checked': {color: blue[900]}}} checked={record} onChange={updateRecordCheckbox} />} label="Enregistrer" /><br/>
                        <FormControl sx={{ m: 1, width:'20ch' }} variant="standard">
                            <InputLabel id="qrcodesize-label">Taille</InputLabel>
                            <Select
                                labelId="qrcodesize-label"
                                id="qrcodesize-select"
                                value={selectedSize}
                                label="Taille"
                                onChange={updateSelectedSize}
                            >
                                <MenuItem key="1" value="240">Web</MenuItem>
                                <MenuItem key="2" value="720">SD</MenuItem>
                                <MenuItem key="3" value="1280">HD</MenuItem>
                                <MenuItem key="4" value="1920">FHD</MenuItem>
                                <MenuItem key="5" value="3840">4K</MenuItem>
                                <MenuItem key="6" value="custom">Personnalisée</MenuItem>
                            </Select>
                        </FormControl>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <FormControl sx={{ m: 1 }} variant="standard">
                            <InputLabel htmlFor="textbox-qrcodesize">Résolution</InputLabel>
                            <BootstrapInput id="textbox-qrcodesize" readOnly={!customSize} value={qrCodeSize} onChange={(e)=>setSize(e.target.value)}/>
                        </FormControl>
                        <br/>
                        <FormControl sx={{ m: 1, width:'20ch' }} variant="standard">
                            <InputLabel id="logos-label">Entité</InputLabel>
                            <Select
                                labelId="logos-label"
                                id="logos-select"
                                value={selectedLogo}
                                label="Entité"
                                onChange={updateSelectedLogo}
                            >
                                {dataLogos.map((item)=><MenuItem key={item.name} value={item.name}>{item.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <FormControl sx={{ m: 1 }} variant="standard">
                            <InputLabel htmlFor="textbox-qrcodecolor">Couleur RGB</InputLabel>
                            <BootstrapInput id="textbox-qrcodecolor" value={qrCodeColor} onChange={(e)=>setColor(e.target.value)}/>
                        </FormControl>
                        {record ?
                            <Form
                                // logo='main'
                                title=""
                                first={QRRECORDED}
                                type="main"
                                form="half"
                                design="transparent"
                                corporate
                            >
                                <Button onClick={checkInputs} sx={{color:'#1B365F'}}>Générer</Button>
                            </Form>
                            :
                            <Form
                                // logo='main'
                                title=""
                                first={QRPONCTUAL}
                                type="main"
                                form="half"
                                design="transparent"
                                corporate
                            >
                                <br/>
                                <Button variant="outlined" endIcon={<SendIcon />} onClick={checkInputs} sx={{color:'#1B365F'}}>Générer</Button>
                            </Form>
                        }
                    </center>
                    {/* End */}
                </Container>
            </BlockContainer>
        </Layout>    
    </>
    );
}


export default SimpleContent;

const BlockContainer = styled.div`
  width: 150vh;
  position: relative;
  left: 20px;
  bottom: 50px;
`;

const BootstrapInput = styled(InputBase)(() => ({
    'label + &': {
      marginTop: useTheme().spacing(2),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      width: '100px',
      textAlign: 'center',
      height:'10px',
      position: 'relative',
      backgroundColor: useTheme().palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: useTheme().transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }));
