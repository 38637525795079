import THEME from '../../styles/theme'
import { useMsal } from '@azure/msal-react'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import { CssBaseline, ThemeProvider,} from "@mui/material"
import { createTheme } from "@mui/material/styles"
import { loginRequest } from '../../mixins/authConfig'

import Logo from '../../components/Illustration/Logo'

import { styled } from 'styled-components'
import WalletRoundedIcon from "@mui/icons-material/WalletRounded"
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import FingerprintIcon from '@mui/icons-material/Fingerprint'
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded"
import HomeIcon from '@mui/icons-material/Home'
import MenuRoundedIcon from "@mui/icons-material/MenuRounded"

import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import Homepage from '../Homepage'
import SimpleContent from '../QrCode/SimpleContent'
import BusinessCard from '../QrCode/BusinessCard'
import Records from '../QrCode/RecordManagement'
import ShortUrl from '../ShortUrl'
import Entities from '../Entities'
import GoogleWalletClassDisplay from '../GoogleWallet/Class/Display'
import GoogleWalletClassCreate from '../GoogleWallet/Class/Create'
import GoogleWalletClassUpdate from '../GoogleWallet/Class/Update'
import GoogleWalletObjectDisplay from '../GoogleWallet/Object/Display'
import GoogleWalletObjectCreate from '../GoogleWallet/Object/Create'
import GoogleWalletObjectUpdate from '../GoogleWallet/Object/Update'
import GoogleWalletObjectDisable from '../GoogleWallet/Object/Disable'
import AppleWalletDisplay from '../AppleWallet/Display'
import AppleWalletCreate from '../AppleWallet/Create'
import AppleWalletUpdate from '../AppleWallet/Update'
import AppleWalletDisable from '../AppleWallet/Disable'
import { useState } from 'react'

export const Root = () => {
    const [collapseSidebar, setCollapseSidebar] = useState(false);
    const keys = JSON.parse(sessionStorage.getItem("msal.token.keys." + loginRequest.clientId) || '""')
    const idToken = JSON.parse(sessionStorage.getItem(keys["accessToken"]) || '""')
    if(sessionStorage.getItem("msal.idtoken") === null || sessionStorage.getItem("msal.idtoken") !== idToken["secret"]){
       sessionStorage.setItem("msal.idtoken", idToken["secret"]);
    }

    const [userName, setUserName] = useState("")
    if(sessionStorage.getItem("msal.account.keys") !== null && userName === ''){
        const keys = JSON.parse(sessionStorage.getItem("msal.account.keys") || '""')
        const currentUserName = JSON.parse(sessionStorage.getItem(keys) || '""')
        setUserName(currentUserName['name'])
     }

    const { instance } = useMsal();
    const handleLogoutRedirect = () => {
      instance.logoutRedirect().catch((error) => console.log(error));
    };

    const theme = createTheme({
        palette: {
          primary: {
            light: "#63b8ff",
            main: "#0989e3",
            dark: "#005db0",
            contrastText: "#000",
          },
          secondary: {
            main: "#4db6ac",
            light: "#82e9de",
            dark: "#00867d",
            contrastText: "#000",
          },
        },
      });
    
    return (
        <>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <div style={{ display: "flex", height: "100vh" }}>
            <SideBarBackground>
            {!collapseSidebar ? <><Hello>Bonjour, {userName}</Hello></> : ''}
              <Sidebar collapsed={collapseSidebar}>
                <Menu 
                  menuItemStyles={{
                  button: {
                    backgroundColor: '#1B365F',
                    color: "#ffffff",
                    
                    [`&:hover`]: {
                      backgroundColor: '#ffffff',
                      color: '#1B365F',
                    },
                    [`&:focus`]: {
                      backgroundColor: '#ffffff',
                      color: '#1B365F',
                    },
                  },
                }}>
                  <MenuItem
                    icon={
                      <MenuRoundedIcon
                        onClick={() => {
                          setCollapseSidebar(!collapseSidebar);
                        }}
                      />
                    }
                  ><Logo type='white'/></MenuItem>
                  <MenuItem component={<Link to="" />} icon={<HomeIcon/>}> Accueil </MenuItem>
                  <MenuItem component={<Link to="entities" />} icon={<FingerprintIcon/>}> Entités </MenuItem>
                  <SubMenu icon={<QrCode2Icon/>} label="QRCode">
                    <MenuItem component={<Link to="qrcode-simple" />}> Contenu simple </MenuItem>
                    <MenuItem component={<Link to="qrcode-vcard" />}> Carte de visite </MenuItem>
                    <MenuItem component={<Link to="qrcode-recorded" />}> Enregistrés </MenuItem>
                  </SubMenu>
                  <MenuItem component={<Link to="short-url" />} icon={<OpenInNewIcon/>}> Short Url </MenuItem>
                  {
                    process.env.REACT_APP_Env === "dev" || process.env.REACT_APP_Env === "sbx" ?
                    <>
                      <SubMenu icon={<WalletRoundedIcon/>} label="Google Wallet">
                        <SubMenu label="Modèles de carte">
                          <MenuItem component={<Link to="google-class-display" />}> Disponibles </MenuItem>
                          <MenuItem component={<Link to="google-class-create" />}> Créer un modèle </MenuItem>
                          <MenuItem component={<Link to="google-class-update" />}> Modifier un modèle </MenuItem>
                        </SubMenu>
                    
                        <SubMenu label="Carte virtuelles">
                          <MenuItem component={<Link to="google-object-display" />}> Disponibles </MenuItem>
                          <MenuItem component={<Link to="google-object-create" />}> Créer une carte </MenuItem>
                          <MenuItem component={<Link to="google-object-update" />}> Modifier une carte </MenuItem>
                          <MenuItem component={<Link to="google-object-disable" />}> Désactiver une carte </MenuItem>
                        </SubMenu> 
                      </SubMenu>
                    </> : null
                  }
                  <SubMenu icon={<WalletRoundedIcon/>} label="Apple Wallet">
                    <SubMenu label="Carte virtuelles">
                      <MenuItem component={<Link to="apple-display" />}> Disponibles </MenuItem>
                      <MenuItem component={<Link to="apple-create" />}> Créer une carte </MenuItem>
                      <MenuItem component={<Link to="apple-update" />}> Modifier une carte </MenuItem>
                      <MenuItem component={<Link to="apple-disable" />}> Désactiver une carte </MenuItem>
                    </SubMenu>
                  </SubMenu> 
                  <MenuItem onClick={handleLogoutRedirect} icon={<LogoutRoundedIcon/>}> Déconnexion </MenuItem>
                </Menu>
              </Sidebar>
              </SideBarBackground>
              <section>
                <Routes>
                  <Route path='/' element={<Homepage />} />
                  <Route path='/entities' element={<Entities />} />
                  <Route path='/qrcode-simple' element={<SimpleContent />} />
                  <Route path='/qrcode-vcard' element={<BusinessCard />} />
                  <Route path='/qrcode-recorded' element={<Records />} />
                  <Route path='/short-url' element={<ShortUrl />} />
                  <Route path='/google-class-display' element={<GoogleWalletClassDisplay />} />
                  <Route path='/google-class-create' element={<GoogleWalletClassCreate />} />
                  <Route path='/google-class-update' element={<GoogleWalletClassUpdate />} />
                  <Route path='/google-object-display' element={<GoogleWalletObjectDisplay />} />
                  <Route path='/google-object-create' element={<GoogleWalletObjectCreate />} />
                  <Route path='/google-object-update' element={<GoogleWalletObjectUpdate />} />
                  <Route path='/google-object-disable' element={<GoogleWalletObjectDisable />} />
                  <Route path='/apple-display' element={<AppleWalletDisplay />} />
                  <Route path='/apple-create' element={<AppleWalletCreate />} />
                  <Route path='/apple-update' element={<AppleWalletUpdate />} />
                  <Route path='/apple-disable' element={<AppleWalletDisable />} />
                </Routes>
              </section>
              </div>
            </ThemeProvider>
        </>
        
    );
};

const SideBarBackground = styled.div`
  background-color: #1B365F;
`;

const Hello = styled.div`
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
`;


