import Logo from '../../Illustration/Logo';
import styled from 'styled-components';
import THEME from '../../../styles/theme';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import Button from "../../../components/Button";
import { BrowserView, MobileView } from "react-device-detect";

interface ILinkProps {
    label: string
    href: string
    subLinks?: ILinkProps[]
}

interface IMenuProps {
    links: ILinkProps[]
    active?: string
    type?: string
    subType?: string
}

interface IMenuContainerProps {
    type?: string
}

export type { IMenuProps }

function Menu(props: IMenuProps) {
    const { instance } = useMsal();
    const handleLogoutRedirect = () => {
        instance.logoutRedirect().catch((error) => console.log(error));
    };
    return (
        <MenuBox>
            <MenuContainer type={props.type}>
                <EmptyBloc></EmptyBloc>
                    {/* <Logo type={props.type} /> */}
            </MenuContainer>
        </MenuBox>
    );
}

export default Menu;

const MenuBox = styled.div`
    position: fixed;
    z-index: 1;
    height: 78px;
    width: 100vw;
    display: flex;
    flex-direction: column;
`;

const EmptyBloc = styled.div`
    padding: 33px;
    width: 100px;
`;

const MenuContainer = styled.div<IMenuContainerProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: ${THEME.text.font};
    background-color: ${props => 
        props.type === 'general' ? THEME.background.color.general : 
        props.type === 'main' ? THEME.background.color.main : 
        props.type === 'primary' ? THEME.background.color.primary : 
        props.type === 'principal' ? THEME.background.color.principal : 
        props.type === 'secondary' ? THEME.background.color.secondary : 
        props.type === 'diverse' ? THEME.background.color.diverse : 
        props.type === 'terciary' ? THEME.background.color.terciary : 
        THEME.background.color.general};
    box-shadow: rgba(0, 0, 0, 0.08) 0 3px 10px 0;
`

// const MenuLink = styled.div<IMenuContainerProps>`
//     display: flex;
// `

// const Links = styled.a<IMenuContainerProps>`
//     text-decoration: none;
//     color: ${props => props.type === 'general' ? THEME.text.color.general :
//         props.type === 'main' ? THEME.text.color.main :
//         props.type === 'primary' ? THEME.text.color.main : 
//         props.type === 'principal' ? THEME.text.color.general : 
//         props.type === 'secondary' ? THEME.text.color.general : 
//         props.type === 'diverse' ? THEME.text.color.main : 
//         props.type === 'terciary' ? THEME.text.color.general : 
//         THEME.text.color.main};
// `

// const LinkContainer = styled.div<IMenuContainerProps>`
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 150px;
//     height: 78px;
        
//     &.active {
//         background-color: ${props => 
//             props.type === 'general' ? THEME.background.color.main : 
//             props.type === 'main' ? THEME.background.color.general : 
//             props.type === 'primary' ? THEME.background.color.content : 
//             THEME.background.color.main};
//         color: ${props => props.type === 'general' ? THEME.text.color.main :
//             props.type === 'main' ? THEME.text.color.general :
//             props.type === 'primary' ? THEME.text.color.main : 
//             THEME.text.color.main}
//     }

//     &:hover{
//         background-color: ${props => 
//             props.type === 'general' ? THEME.background.color.main : 
//             props.type === 'main' ? THEME.background.color.general : 
//             props.type === 'primary' ? THEME.background.color.content : 
//             THEME.background.color.main};
//         color: ${props => props.type === 'general' ? THEME.text.color.main :
//             props.type === 'main' ? THEME.text.color.general :
//             props.type === 'primary' ? THEME.text.color.main : 
//             THEME.text.color.main}
//     }
// `;

// const EmptyDropdown = styled.div`
//     width: 150px;
// `;
